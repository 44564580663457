import { Tabs } from "@amzn/awsui-components-react";
import { CanonicalBusinessEvent } from "../types";
import CbeResults from "./CbeResults";

interface Props {
  cbeData: CanonicalBusinessEvent[];
}

const createVersionNumberArray = (cbeData: CanonicalBusinessEvent[]) => {
  return Array.from(
    new Set(cbeData.map((cbe) => cbe.lockVersion ?? cbe.idempotenceVersion))
  )
    .sort()
    .reverse();
};

export default function CbeVersionSelector(props: Props) {
  const { cbeData } = props;

  return (
    <Tabs
      tabs={createVersionNumberArray(cbeData).map((version) => ({
        id: `version-${version}`,
        label: `Version ${version}`,
        content: (
          <CbeResults
            cbeData={cbeData.filter(
              (cbe) => (cbe.lockVersion ?? cbe.idempotenceVersion) === version
            )}
          />
        ),
      }))}
    />
  );
}
