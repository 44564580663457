import {useQuery} from "@tanstack/react-query";
import {EventsApiFactory} from "../lusca-search-api/LuscaSearchApiFactory";
import {AccountingEventsGroup, DigitalBusinessDocument} from "../types";
import removeEmpty from "../helpers/removeEmpty";

export default function useFetchEvent(stage: string, postId: string) {
  const EventsApi = EventsApiFactory();

  const getEvent = async () => {
    const { data } = await EventsApi.getEvents(stage, postId);
    return data.map((element) => {
      // Check if documentType exists in the cleaned element
      if ("documentType" in element) {
        return removeEmpty(element) as DigitalBusinessDocument;
      }
      return removeEmpty(element) as AccountingEventsGroup;
    });
  };

  return useQuery(["post event", postId], () => getEvent(), {
    refetchOnWindowFocus: false,
    enabled: false, // disable this query from automatically running
  });
}
