import { Tabs } from "@amzn/awsui-components-react";
import { AccountingEventsGroup, DigitalBusinessDocument } from "../types";
import EventResults from "./EventResults";

interface Props {
  eventData: (AccountingEventsGroup | DigitalBusinessDocument)[];
}

const createVersionNumberArray = (length: number) => {
  return Array.from(Array(length).keys()).reverse();
};

export default function EventsVersionSelector(props: Props) {
  const { eventData } = props;

  return (
    <Tabs
      tabs={createVersionNumberArray(eventData.length).map((colName) => ({
        id: `${colName + 1}`,
        label: `Version ${colName + 1}`,
        content: <EventResults eventData={eventData[colName]} />,
      }))}
    />
  );
}
