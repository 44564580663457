import React, { useContext } from "react";

import {
  Box,
  Container,
  ExpandableSection,
  Popover,
  StatusIndicator,
  TextContent,
  Link,
} from "@amzn/awsui-components-react";

import ReactJson from "react-json-view";
import { CanonicalBusinessEvent } from "../types";
import DarkModeContext from "../contexes/DarkModeContext";
import { BASE_16_CUSTOM_DARK_THEME } from "../constants";
import { createFlashUILink } from "./OrderIdBar/ExternalLinks";
import { useMarketplaceId } from "../contexes/MarketplaceIdContext";
import { StageContext } from "../contexes/OrderIdContext";

interface Props {
  cbe: CanonicalBusinessEvent;
}

export default function CbeEntry(props: Props) {
  const { cbe } = props;
  const darkMode = useContext(DarkModeContext);
  return (
    <Container>
      {PublishStatusComponent(cbe)}
      <ExpandableSection
        variant="default"
        header={
          <Box variant="h4">
            {cbe.idempotenceId} (V{cbe.idempotenceVersion})
            <br />
            {cbe.creationDate && (
              <TextContent>
                <small>Accounting Operation: {cbe.accountingOperation}</small>
                <br />
                <small>Destination: {cbe.destination} </small>
                <br />
                <small>
                  Created on:{" "}
                  {new Date(cbe.creationDate).toISOString().replace("T", " ")}
                </small>
                <br />
                <small>
                  PK: {cbe.pk}, SK: {cbe.sk}
                </small>
              </TextContent>
            )}
          </Box>
        }
      >
        <ReactJson
          src={cbeWithoutPublishStatus(cbe)}
          displayDataTypes={false}
          collapsed={2}
          sortKeys
          theme={darkMode ? BASE_16_CUSTOM_DARK_THEME : "rjv-default"}
        />
      </ExpandableSection>
    </Container>
  );
}

// Since publish status is not an actual field from a CBE,
// we can delete it from the JSON as we've already rendered it in the Popover
function cbeWithoutPublishStatus(cbe: CanonicalBusinessEvent) {
  const cbeCopy = { ...cbe };
  delete cbeCopy.publishStatus;
  return cbeCopy;
}

function PublishStatusComponent(cbe: CanonicalBusinessEvent) {
  const stage = useContext(StageContext);
  const { marketplaceId } = useMarketplaceId();

  if (cbe.idempotenceId.includes("NONE")) {
    return <StatusIndicator type="info">NoOp</StatusIndicator>;
  }
  if (cbe.publishStatus === null || cbe.publishStatus === undefined) {
    return (
      <StatusIndicator type="pending">
        Status Unknown/Pending Publish
      </StatusIndicator>
    );
  }
  if (cbe.publishStatus?.successFlag === true) {
    return (
      <Link
        external
        externalIconAriaLabel="Opens in a new tab"
        href={createFlashUILink(marketplaceId, cbe.idempotenceId, stage)}
        data-testid="FlashLink"
      >
        <StatusIndicator type="success">Publish Success</StatusIndicator>
      </Link>
    );
  }
  return (
    <Popover
      fixedWidth={false}
      size="large"
      triggerType="text"
      header={cbe.publishStatus?.message}
      content={cbe.publishStatus?.stackTrace || "No error details"}
    >
      <StatusIndicator type="error">Error Publishing</StatusIndicator>
    </Popover>
  );
}
