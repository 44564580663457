import { ColumnLayout, Container, Header } from "@amzn/awsui-components-react";
import { useContext } from "react";
import useFetchEvent from "../../hooks/useFetchEvent";
import ExternalLinks from "./ExternalLinks";
import { OrderIdContext, StageContext } from "../../contexes/OrderIdContext";
import { AccountingEventsGroup, DigitalBusinessDocument } from "../../types";

export default function OrderIdBar() {
  const orderId = useContext(OrderIdContext);
  const stage = useContext(StageContext);

  const { data: eventData } = useFetchEvent(stage, orderId);

  const renderExternalLinks = () => {
    if (eventData && eventData[0]) {
      if ('documentType' in eventData[0]) {
        // Handle DigitalBusinessDocument
        const dbd = eventData[0] as DigitalBusinessDocument;
        return <ExternalLinks digitalBusinessDocument={dbd} />;
      } else {
        // Handle AccountingEventsGroup
        const aeg = eventData[0] as AccountingEventsGroup;
        return <ExternalLinks accountingEventsGroup={aeg} />;
      }
    }
    return null;
  };

  return (
    <Container>
      <ColumnLayout columns={2}>
        <Header variant="h1" description="Order ID">
          {orderId}{" "}
        </Header>
        {renderExternalLinks()}
      </ColumnLayout>
    </Container>
  );
}

