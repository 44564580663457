import {
  Box,
  Header,
  Table,
  Pagination,
  TextFilter,
  Link,
} from "@amzn/awsui-components-react";
import React, { useState } from "react";
import { SearchHistory } from "../types";

export default function SearchHistory() {
  const orderIds: SearchHistory[] = JSON.parse(
    localStorage.getItem("orderIds") || "[]"
  ) as SearchHistory[];

  const [currentPage, setCurrentPage] = useState(1);
  const [filteringText, setFilteringText] = useState("");
  const resultsPerPage = 15;

  // Filter items based on filteringText
  const filteredItems = orderIds.filter(
    (item) =>
      item.orderId?.toLowerCase().includes(filteringText.toLowerCase()) ||
      item.countryCode?.toLowerCase().includes(filteringText.toLowerCase()) ||
      item.stage?.toLowerCase().includes(filteringText.toLowerCase())
  );

  // Calculate the range of items to display for the current page
  const startIndex = (currentPage - 1) * resultsPerPage;
  const endIndex = startIndex + resultsPerPage;
  const paginatedItems = filteredItems.slice(startIndex, endIndex);

  return (
    <Table
      columnDefinitions={[
        {
          id: "id",
          header: "Id",
          width: 100,
          cell: (item) => item.index || "-",
        },
        {
          id: "orderId",
          header: "Order Id",
          width: 600,
          minWidth: 550,
          cell: (item) => (
            <Link
              href={`?stage=${encodeURIComponent(
                item.stage
              )}&orderId=${encodeURIComponent(item.orderId)}`}
            >
              {item.orderId}
            </Link>
          ),
        },
        {
          id: "stage",
          header: "Stage",
          width: 100,
          cell: (item) => item.stage || "-",
        },
        {
          id: "countryCode",
          header: "Country",
          width: 100,
          cell: (item) => item.countryCode || "-",
        },
        {
          id: "date",
          header: "Last Accessed",
          width: 150,
          cell: (item) => item.date || "-",
        },
      ]}
      items={paginatedItems.map((obj, index) => ({
        index: startIndex + index + 1,
        orderId: obj.orderId,
        stage: obj.stage,
        countryCode: obj.countryCode,
        date: obj.date,
      }))}
      loadingText="Loading resources"
      empty={
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No resources to display.
          </Box>
        </Box>
      }
      filter={
        <TextFilter
          filteringPlaceholder="Filter by Order ID, Country, or Stage"
          filteringText={filteringText}
          onChange={({ detail }) => setFilteringText(detail.filteringText)}
        />
      }
      header={<Header>Recent Searches</Header>}
      pagination={
        <Pagination
          currentPageIndex={currentPage}
          pagesCount={Math.ceil(filteredItems.length / resultsPerPage)}
          onChange={({ detail }) => setCurrentPage(detail.currentPageIndex)}
        />
      }
      resizableColumns
    />
  );
}
