import { SpaceBetween } from "@amzn/awsui-components-react";
import { CanonicalBusinessEvent } from "../types";
import CbeEntry from "./CbeEntry";

interface Props {
  cbeData: CanonicalBusinessEvent[];
}

export default function CbeResults({ cbeData }: Props) {
  return (
    <SpaceBetween size="xl">
      {cbeData.map((cbe, index) => (
        <CbeEntry
          key={`${cbe.pk}-${cbe.idempotenceVersion}-${index}`}
          cbe={cbe}
        />
      ))}
    </SpaceBetween>
  );
}
