import { useQuery } from "@tanstack/react-query";
import { CbesApiFactory } from "../lusca-search-api/LuscaSearchApiFactory";
import { CanonicalBusinessEvent } from "../types";
import removeEmpty from "../helpers/removeEmpty";

export default function useFetchCbe(stage: string, postId: string) {
  const CbesApi = CbesApiFactory();

  const getCbe = async () => {
    const { data } = await CbesApi.getCbes(stage, postId);
    const cbeData = data.map((element) => {
      return removeEmpty(
        element as CanonicalBusinessEvent
      ) as CanonicalBusinessEvent;
    });
    return cbeData;
  };

  return useQuery(["post1 cbe", postId], () => getCbe(), {
    refetchOnWindowFocus: false,
    enabled: false, // disable this query from automatically running
  });
}
