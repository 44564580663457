import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import MainDataPage from "../MainDataPage";
import SearchBar from "../SearchBar";

interface Props {
  setStage: React.Dispatch<React.SetStateAction<string>>;
}

// The content in the main content area of the App layout
export default function HomepageContent({ setStage }: Props) {
  return (
    <SpaceBetween size="xl">
      <SearchBar setStage={setStage}>
        <MainDataPage />
      </SearchBar>
    </SpaceBetween>
  );
}
