import { EventsApi, CbesApi } from "./generated-src";
import { getAppSetting } from "../config/AppSettings";
import { Configuration } from "./generated-src/configuration";

export function EventsApiFactory() {
  const apiBasePath = `https://${getAppSetting("apiUrl")}/jwt`;
  return new EventsApi(new Configuration({ basePath: apiBasePath }));
}

export function CbesApiFactory() {
  const apiBasePath = `https://${getAppSetting("apiUrl")}/jwt`;
  return new CbesApi(new Configuration({ basePath: apiBasePath }));
}
